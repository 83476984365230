import { clamp, range } from 'lodash'

import { RowWithData } from '../../data/types'

import {
  ImageGridDownloadOptions,
  cardAspectRatio,
  maxImageDimension,
  maxImages,
} from './configuration'
import { CompositeCardImageLayout } from './CardLayout'
import { notNan } from 'src/utils/typeConversions'

function columnForCard(row: RowWithData) {
  const inputValue =
    row?.input?.['cmc'] ??
    row?.input?.['converted_mana_cost'] ??
    row?.input?.['mana_value'] ??
    row?.input?.['manavalue'] ??
    row?.input?.['mv']

  if (typeof inputValue == 'string') {
    const numericValue = parseInt(inputValue)
    if (!isNaN(numericValue)) {
      return numericValue
    }
  }

  return row.card?.cmc ?? 0
}

export function generateColumnLayout(
  rows: RowWithData[],
  options: ImageGridDownloadOptions,
): CompositeCardImageLayout {
  const { columns, skipEmptyColumns, backgroundColor } = options

  const errors = []

  if (rows.length > maxImages) {
    errors.push(`A maximum of ${maxImages} will be rendered.`)
  }

  const maxCMC = rows.reduce(
    (result, row) => Math.max(result, columnForCard(row)),
    0,
  )

  let curveColumns = rows.slice(0, maxImages).reduce((result, row) => {
    const imageURL =
      row.card?.image_uris?.normal ??
      row.card?.card_faces?.[0]?.image_uris.normal

    if (imageURL) {
      const column = columnForCard(row)

      result[column].push({
        ...row,
        imageURL,
      })
    }

    return result
  }, range(0, maxCMC + 1).map(() => []) as (RowWithData & { imageURL: string })[][])

  if (skipEmptyColumns) {
    curveColumns = curveColumns.filter((column) => column.length > 0)
  }

  if (curveColumns.length > columns) {
    curveColumns = [
      ...curveColumns.slice(0, columns - 1),
      curveColumns.slice(columns - 1).flatMap((c) => c),
    ]
  }

  const imageWidth = clamp(options.imageWidth, 16, maxImageDimension)
  const padding = clamp(notNan(options.padding) ?? 0, 0, options.imageWidth / 2)
  const gutter = clamp(
    notNan(options.gutter) ?? 0,
    0,
    (options.imageWidth - padding * 2) / (columns - 1),
  )

  const columnWidth =
    (imageWidth - padding * 2 - gutter * (columns - 1)) / columns
  const cardHeight = columnWidth * cardAspectRatio
  const rowHeight = columnWidth * 0.2
  const countHeight = rowHeight

  let imageHeight = curveColumns.reduce((result, column) => {
    const columnHeight =
      padding * 2 +
      column
        .slice(0, -1)
        .reduce(
          (columnResult, row) =>
            columnResult +
            rowHeight +
            (row.input?.count != null && parseInt(row.input?.count) > 1
              ? countHeight
              : 0),
          0,
        ) +
      cardHeight

    return Math.max(result, columnHeight)
  }, 0)

  imageHeight = Math.ceil(imageHeight)

  if (imageHeight > maxImageDimension) {
    errors.push('Image is too large and will be clipped.')
    imageHeight = maxImageDimension
  }

  const cards = []
  const text = []

  for (const [columnIndex, column] of curveColumns.entries()) {
    let y = padding

    for (const row of column) {
      const x = columnIndex * (columnWidth + gutter) + padding

      cards.push({
        url: row.imageURL,
        x,
        y,
        width: columnWidth,
        height: cardHeight,
      })

      if (row.input?.count != null && parseInt(row.input?.count) > 1) {
        text.push({
          text: row.input.count.toString(),
          x: x + columnWidth * 0.88,
          y: y + cardHeight * 0.13,
          size: columnWidth * 0.18,
        })

        y += countHeight
      }

      y += rowHeight
    }
  }

  return {
    imageSize: {
      width: imageWidth,
      height: imageHeight,
    },
    cardSize: {
      width: columnWidth,
      height: imageHeight,
    },
    backgroundColor,
    cards,
    text,
    errors,
  }
}
